@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.formMargins {
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    /* margin-bottom: 32px; */
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.listingTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}
.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

.unitTypeHidden {
  display: none;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}


.careGiver{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 15px;
  .fromField{
    position: relative;
    input, select{
      border-radius: 6px;
      /* min-height: 48px; */
    }
  }
  .dob{
    input{
      height: 48px;
      min-height: 48px;
      background-position: 7px 15px;
    }
  }
  .textAreaBlock{
    grid-column: span 2;
  }
  .serviveProvideField{
    label{
      + div{
        > span{
          + span{
            + div{
              min-height: 48px;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  @media screen and (width < 768px){
    display: flex;
        flex-direction: column;
        gap: 30px;
        .dob{
          input{
            height: 36px;
            min-height: 36px;
            background-position: 7px 9px;
          }
        }
        .serviveProvideField{
          label{
            + div{
              > span{
                + span{
                  + div{
                    min-height: 36px;
                    border-radius: 6px;
                  }
                }
              }
            }
          }
        }
  }
}