.root {
  & input {
    box-shadow: none;
  }
}

.root :global(.group-head-collapse) {
  display: none;
}

.notice {
  color: var(--colorGrey500);
  font-weight: var(--fontWeightRegular);
}

.checkboxOption {
  display: inline-block;
}

.option {
  display: flex;
  align-items: center;
}

.checkboxOption {
  width: 16px;
  height: 16px;
  margin-left: 12px;
  position: absolute;
}

.optionLabel {
  padding-left: 36px;
}

.groupHeading {
  font-weight: var(--fontWeightSemiBold);
  display: flex;
  padding-left: 12px;
  align-items: center;
}

.iconRoot {
  stroke: var(--colorGrey700);
  fill: var(--colorGrey700);
  width: 6px;
  height: 10px;
}
