/* Improve Calendar Header UI */
.detailsNEwData .calendar-header {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 8px;
  }
  
  /* Custom Dropdown Styling */
  .detailsNEwData .custom-dropdown {
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
  .detailsNEwData .custom-dropdown:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .detailsNEwData .CalendarMonth_caption {  
    padding-top: 55px; 
  }  
  .detailsNEwData .DayPicker_weekHeader {  
    top: 130px;
  }
  .detailsNEwData .DateInput,
  .detailsNEwData .SingleDatePickerInput__withBorder{
    width: 100%;
  }
 .detailsNEwData .DateInput_input__focused {
    border: none;
}
.detailsNEwData .DateInput_input {
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
    border: 1px solid #b8bfd1;
    font-weight: 300;
    color: #000000;
}
