@import '../../../../../styles/customMediaQueries.css';

.radioButtons {
}

.checkedAvailable {
  display: none;
  stroke: var(--colorSuccess);
  fill: var(--colorSuccess);
}
.checkedNotAvailable {
  display: none;
  stroke: var(--colorFail);
  fill: var(--colorFail);
}
